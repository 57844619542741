import classnames from 'classnames'
import dynamic from 'next/dynamic'
import React, { PropsWithChildren, useState } from 'react'
import HomeBannerComponent from '../../../components/HomeBanner'
import { withSlice } from '../../SliceComponent'
import classes from './homeBannerSlice.module.scss'
import { SbBlokData } from '@storyblok/react'

export type HomeBannerType = {
  title: string
  sub_title: string
  banner_day_image: {
    filename: string
  }
  banner_night_image: {
    filename: string
  }
}
export type HomeVideoType = {
  video_type: string
  day_video_id: string
  night_video_id: string
}

export type HomeVideoLightBoxType = {
  video_link: string
  play_button_text: string
  button_plus_icon: boolean
  custom_link_cta_text?: string
  custom_link_url?: {
    cached_url: string
    linktype: string
  }
}

export type HomeBannerAndVideoProps = SbBlokData & {
  home_banner: HomeBannerType[]
  is_home_video: boolean
  home_video: HomeVideoType[]
  home_video_lightbox: HomeVideoLightBoxType[]
  component?: string
  _uid?: string
}

const HomeBanner: React.FC<PropsWithChildren<{ homeBannerData?: HomeBannerType; homeVideoData?: HomeVideoType; is_home_video: boolean; lightBoxStatus: boolean }>> = ({
  homeBannerData,
  homeVideoData,
  is_home_video,
  lightBoxStatus })=> {
  return (
    <>
      <HomeBannerComponent
        title={homeBannerData?.title}
        subtitle={homeBannerData?.sub_title}
        dayImage={homeBannerData?.banner_day_image?.filename}
        nightImage={homeBannerData?.banner_night_image?.filename}
        isHomeVideo={is_home_video}
        videoType={homeVideoData?.video_type}
        dayVideoId={homeVideoData?.day_video_id}
        nightVideoId={homeVideoData?.night_video_id}
        lightBoxStatus={lightBoxStatus}
      />
    </>
  )
}

const LazyLightbox = dynamic(() => import('../../../components/HomeVideoLightbox'))

const HomeLightBoxVideo: React.FC<PropsWithChildren<{homeVideoLightBoxData: HomeVideoLightBoxType; lightBoxStatus(status: boolean): void}>> = ({ homeVideoLightBoxData, lightBoxStatus })=> {
  return (
    <LazyLightbox
      videoLink={homeVideoLightBoxData?.video_link}
      textPlayBtn={homeVideoLightBoxData?.play_button_text}
      customCtaText = {homeVideoLightBoxData?.custom_link_cta_text}
      customCtaLink = {homeVideoLightBoxData?.custom_link_url?.cached_url}
      customLinkTarget = {homeVideoLightBoxData?.custom_link_url?.linktype === 'story' ? '_self' : '_blank'}
      lightBoxStatus = {lightBoxStatus}
    />
  )
}

export const HomeBannerAndVideo:React.FC<PropsWithChildren<{slice: HomeBannerAndVideoProps}>> = ({ slice })=> {
  const homeBannerData = slice.home_banner[0]
  const homeVideoData = slice.home_video[0]
  const homeVideoLightBoxData = slice?.home_video_lightbox[0]
  const [lightBoxVisible, setLightBoxVisible] = useState(false)

  const updateLightBox = (showLightBox: boolean)=> {
    setLightBoxVisible(showLightBox)
  }

  return (
    <div className={classnames(classes.homeBannerVideoContainer)}>
      <HomeBanner homeBannerData= {homeBannerData} homeVideoData={homeVideoData} is_home_video={slice.is_home_video} lightBoxStatus = {lightBoxVisible} />
      <div className={classnames(classes.lightBoxVideoWrapper)}>
        {homeVideoLightBoxData ? (<HomeLightBoxVideo homeVideoLightBoxData={homeVideoLightBoxData} lightBoxStatus={updateLightBox}/>) : null}
      </div>
    </div>
  )
}

export default withSlice(HomeBannerAndVideo)
