import React, { FC, useEffect, useState } from 'react'
import { IHomeBannerComponentProps } from './homeBannerModels'
import { TitleSubtitleHomePage } from './titleSubtitle'
import homeBannerStyles from './homeBanner.module.scss'
import { useInView } from 'react-intersection-observer'
import { useMeasure } from './useMeasure'
import { useTitleTopValue } from './useTitleTopValue'
import { IMAGE_FILTER } from '../../utils/detectWebPSupported'
import { useScrollerMotion } from 'scroller-motion'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import { useMobileDetect } from '../../hooks/useDeviceDetect'
import Image from 'next/image'
import { isSsr } from '../../utils/isSsr'
import Head from 'next/head'
import topCurvedSvgMobile from '../../../public/images/topCurved_bg_mobile.svg'
import topCurvedSvgDefault from '../../../public/images/topCurved_bg_default.svg'
import topCurvedSvg4k from '../../../public/images/topCurved_bg_4k.svg'
import bottomCurvedSvgMobile from '../../../public/images/bottomCurved_bg_mobile2.svg'
import bottomCurvedSvgTablet from '../../../public/images/bottomCurved_bg_mobile4.svg'
import bottomCurvedSvgDefault from '../../../public/images/bottomCurved_bg_default.svg'
import bottomCurvedSvgDesktop from '../../../public/images/bottom_curve_new.svg'
import bottomCurvedSvg4k from '../../../public/images/bottomCurved_bg_4k.svg'
import { motion } from 'framer-motion'
import dynamic from 'next/dynamic'

// @ts-ignore
const LazyBannerVideo = dynamic(() => import('../ProjectBanner/ProjectVideoBanner').then((mod) => mod.ProjectVideoBanner), { ssr: false })

const HomeBannerComponent: FC<IHomeBannerComponentProps> = props => {
  const defaultWidth = typeof window !== 'undefined' ? window.innerWidth : 0
  const { title, subtitle, dayImage, nightImage, isHomeVideo, videoType, dayVideoId, nightVideoId, lightBoxStatus } = props
  const hours = Number.parseInt(new Date().toLocaleString('en-AU', {
    hour: '2-digit',
    hour12: false,
    timeZone: 'Australia/Adelaide',
  }))
  const isDayTime = isSsr() ? true : hours >= 6 && hours < 18
  const [offset, setOffset] = useState(0)
  const [rmScroll, setRmScroll] = useState(false)
  const [delta, setDelta] = useState(900)
  const [width, setWidth] = useState(defaultWidth)
  const [ref, inView] = useInView()
  const { y } = useScrollerMotion()
  const { isMobile, isDesktop } = useMobileDetect()
  const isMobileView = useMediaQuery('(max-width: 768px')

  useEffect(() => {
    window.addEventListener('load', function () {
      setWidth(window.innerWidth)
    }, false)
    window.addEventListener('resize', function () {
      setWidth(window.innerWidth)
    }, false)

    return () => {
      window.removeEventListener('load', function () {
        setWidth(window.innerWidth)
      }, false)
      window.removeEventListener('resize', function () {
        setWidth(window.innerWidth)
      }, false)
    }
  }, [width])

  useEffect(() => {
    function handleScroll() {
      setOffset(window.scrollY)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      setRmScroll(delta <= 900)
    }
  }, [delta, width])

  const handleScrollDelta = (e: React.WheelEvent) => {
    if (delta > 900) {
      setDelta(900)

      return
    }

    if (width < 640 && delta < 467) {
      setDelta(467)

      return
    }

    if (width <= 1440 && delta < 580) {
      setDelta(580)

      return
    }

    if (width <= 1920 && inView) {
      setDelta(900 - offset)

      return
    }

    if (width <= 2560 && inView) {
      setDelta(900 - offset)

      return
    }

    if (width < 4000 && inView) {
      setDelta(900 - offset)

      return
    }

    if (e.deltaY >= 0) {
      setDelta(delta - 1)
    } else if (e.deltaY <= 0) {
      setDelta(delta + 1)
    }
  }

  const [titleTopValue] = useTitleTopValue([offset, width])
  const [topHeight, bottomHeight] = useMeasure([width, delta, offset])

  const getMarginTopForOpacity = () => {
    if (titleTopValue) {
      return 1 - (offset / (titleTopValue - 100 < 0 ? 0 : titleTopValue - 100))
    }
  }

  const shouldAnimate = !isMobile() && isDesktop() && !isMobileView

  return (
    <>
      <Head>
        {/* Top overlay preloads */}
        <link rel="preload" as="image" href={topCurvedSvgMobile.src} media="(max-width: 767px)" />
        <link rel="preload" as="image" href={topCurvedSvgDefault.src} media="(min-width: 768px) and (max-width: 2999px)" />
        <link rel="preload" as="image" href={topCurvedSvg4k.src} media="(min-width: 3000px)" />
        {/* Bottom overlay preloads */}
        <link rel="preload" as="image" href={bottomCurvedSvgMobile.src} media="(max-width: 539px)" />
        <link rel="preload" as="image" href={bottomCurvedSvgTablet.src} media="(min-width: 540px) and (max-width: 767px)" />
        <link rel="preload" as="image" href={bottomCurvedSvgDefault.src} media="(min-width: 768px) and (max-width: 1719px)" />
        <link rel="preload" as="image" href={bottomCurvedSvgDesktop.src} media="(min-width: 1720px) and (max-width: 2999px)" />
        <link rel="preload" as="image" href={bottomCurvedSvg4k.src} media="(min-width: 3000px)" />
      </Head>
      <div className={homeBannerStyles.homeBannerWrapper}
        onWheel={(e) => {
          width > 768 && handleScrollDelta(e)
        }}
        style={{
          transform: `translateY(${offset * -0.01}px)`,
          overflowY: !rmScroll ? 'hidden' : (width < 768 ? 'unset' : 'unset'),
        }}
      >
        <Image alt="" className={homeBannerStyles.image} priority src={`${isDayTime ? dayImage : nightImage}${IMAGE_FILTER}`} layout={'fill'} />
        {
          (isHomeVideo && dayVideoId && nightVideoId) ? (
            <div className={homeBannerStyles.videoWrapper}>
              <LazyBannerVideo videoId={!isDayTime ? nightVideoId : dayVideoId} videoType={videoType || 'youtube'} lightBoxStatus={lightBoxStatus} customClass={'homeVideo'}/>
            </div>
          ) : null
        }
        <div className={homeBannerStyles.mainContentWrapper}>
          <div className={homeBannerStyles.topCurvedWrapper}>
            <motion.div
              ref={ref}
              className={homeBannerStyles.topCurved}
              style={{
                overflowY: !rmScroll ? 'scroll' : 'unset',
                height: isSsr() ? undefined : topHeight,
                ...(shouldAnimate ? { y } : {}),
              }}
            ></motion.div>
            <motion.div
              className={homeBannerStyles.titlesWrapper}
              style={{
                top: isSsr() ? undefined : titleTopValue,
                opacity: shouldAnimate ? getMarginTopForOpacity() : 1,
                ...(shouldAnimate ? { y } : {}),
              }}
            >
              <TitleSubtitleHomePage
                title={title}
                subtitle={subtitle}
              />
            </motion.div>
          </div>
          <div
            style={{
              overflowY: !rmScroll ? 'scroll' : 'unset',
              height: isSsr() ? undefined : bottomHeight,
            }}
            className={homeBannerStyles.bottomCurved}
          />
        </div>
      </div>
    </>
  )
}

export default HomeBannerComponent
