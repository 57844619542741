import { useEffect, useState } from 'react'

export const useMeasure = (deps: [width: number, deltaValue: number, offset: number]) => {
  const [bottomHeight, setBottomHeight] = useState(0)
  const [topHeight, setTopHeight] = useState(0)
  const updateTopBottomHeight = (deps: [width: number, deltaValue: number, offset: number]) => {
    const [width, deltaValue, offset] = deps

    if (width !== 0) {
      if (width <= 460) {
        setTopHeight(deltaValue - 370)
        setBottomHeight(425)

        return
      } else if (width <= 640) {
        setTopHeight(deltaValue - 375)
        setBottomHeight(425)

        return
      } else if (width <= 767) {
        setTopHeight(deltaValue - 360)
        setBottomHeight(425)

        return
      } else if (width <= 1023) {
        setTopHeight(510 - offset)
        setBottomHeight(382)

        return
      } else if (width <= 1024) {
        setTopHeight(960)
        setBottomHeight(425)

        return
      } else if (width <= 1175) {
        setTopHeight(960)
        setBottomHeight(500)
      } else if (width <= 1200) {
        setTopHeight(960)
        setBottomHeight(500)

        return
      } else if (width <= 1280) {
        setTopHeight(960)
        setBottomHeight(500)

        return
      } else if (width <= 1440) {
        setTopHeight(960)
        setBottomHeight(570)

        return
      } else if (width <= 1520) {
        setTopHeight(960)
        setBottomHeight(655)

        return
      } else if (width <= 1920) {
        setTopHeight(960)
        setBottomHeight(655)

        return
      } else if (width <= 2100) {
        setTopHeight((width * .6) - offset)
        setBottomHeight(780)

        return
      } else if (width <= 2560) {
        setTopHeight(1250 - offset)
        setBottomHeight(780)

        return
      } else if (width < 2700) {
        setTopHeight(1435 - offset)
        setBottomHeight(1465)

        return
      } else if (width < 3000) {
        setTopHeight(1500 - offset)
        setBottomHeight(1465)

        return
      } else if (width < 3300) {
        setBottomHeight(1365)
        setTopHeight((width * .57) - offset)

        return
      } else if (width < 3500) {
        setBottomHeight(1390)
        setTopHeight((width * .56) - offset)

        return
      } else if (width < 4000) {
        setBottomHeight(width * .37)
        setTopHeight((width * .56) - offset)

        return
      }
    }
  }

  useEffect(() => {
    updateTopBottomHeight(deps)
  }, deps)

  return [topHeight, bottomHeight]
}
