import { useEffect, useState } from 'react'

export  const useTitleTopValue = (deps:[offest: number, width: number]) => {
  const [topValue, setTopValue] = useState(220)
  const getTitleMarginTop = (deps:[offset: number, width: number]) => {
    const [offset, width] = deps

    if (width <= 640){
      setTopValue(90)
    } else if (width <=767){
      setTopValue(120)
    } else if (width <= 768){
      setTopValue(150 - offset)
    } else if (width <= 1023){
      setTopValue(150 - offset)
    } else if (width < 1440){
      setTopValue(240 - offset)
    } else if (width < 1640){
      setTopValue(200 - offset)
    } else if (width <= 1920){
      setTopValue(260 - offset)
    } else if (width <=2560){
      setTopValue(250 - offset)
    } else {
      setTopValue(400 - offset)
    }
  }

  useEffect(()=> {
    getTitleMarginTop(deps)
  }, deps)

  return [topValue]
}
