import React, { FC } from 'react'
import { ITitleSubtitleProps } from '../homeBannerModels'
import titleSubtitleStyles from './titleSubtitle.module.scss'

export const TitleSubtitleHomePage: FC<ITitleSubtitleProps> = props => {
  const { title, subtitle } = props

  return (
    <div className={titleSubtitleStyles?.titleSubtitleWrapper}>
      <h1 className={titleSubtitleStyles?.title}>{title}</h1>
      <div className={titleSubtitleStyles?.subtitle}>{subtitle}</div>
    </div>
  )
}
